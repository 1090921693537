import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  Grid,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContent,
  TextField,
  Button
} from '@material-ui/core';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';

import {
  Budget,
  TotalUsers,
  TasksProgress,
  TotalProfit,
  LatestSales,
  UsersByDevice,
  LatestProducts,
  LatestOrders
} from './components';
import {
  getAdAnalytics,
  getCommonAnalytics,
  updateCampaignPrice
} from '../../store/actions/analyticsAction';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const Dashboard = props => {
  const classes = useStyles();
  const {
    getCommonAnalytics,
    getAdAnalytics,
    ad_analytics,
    common_analytics,
    updateCampaignPrice
  } = props;

  const [isOpen, setModal] = useState(false);
  const [editedCampaign, updateCampaign] = useState(null);
  const [selectedDate, setDate] = useState(new Date());

  useEffect(() => {
    getCommonAnalytics();
  }, []);

  useEffect(() => {
    getCommonAnalytics();
    getAdAnalytics(selectedDate.getMonth() + 1, selectedDate.getFullYear());
  }, [selectedDate]);

  const openModal = item => {
    setModal(true);
    updateCampaign(item);
  };

  const handleDateChange = date => {
    setDate(new Date(date));
  };

  const handleUpdateCampaign = () => {
    updateCampaignPrice(
      editedCampaign?._id?.campaign_id,
      editedCampaign.price
    ).then(res => {
      setModal(false);
      updateCampaign(null);
      getAdAnalytics(selectedDate.getMonth() + 1, selectedDate.getFullYear());
    });
  };
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <Budget value={Math.round(common_analytics?.lifetime || 0)} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalUsers value={Math.round(common_analytics?.averageСheck || 0)} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TasksProgress value={Math.round(common_analytics?.LTV || 0)} />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <TotalProfit
            value={Math.round(common_analytics?.averageValue || 0)}
          />
        </Grid>
        <Grid item lg={12} md={12} xl={12} xs={12}>
          <LatestSales
            data={ad_analytics}
            selectedDate={selectedDate}
            handleDateChange={handleDateChange}
          />
          <LatestOrders
            data={ad_analytics}
            openModal={item => openModal(item)}
          />
        </Grid>
        {/* <Grid item lg={4} md={6} xl={3} xs={12}>
          <UsersByDevice />
        </Grid>
        <Grid item lg={4} md={6} xl={3} xs={12}>
          <LatestProducts />
        </Grid>
        <Grid item lg={8} md={12} xl={9} xs={12}>
          <LatestOrders />
        </Grid> */}
      </Grid>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        minWidth={'sm'}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Update campaign price'}
        </DialogTitle>
        <DialogContent>
          <TextField
            value={editedCampaign?.price}
            name="price"
            onChange={e =>
              updateCampaign({ ...editedCampaign, price: e.target.value })
            }
            label="Price"
            variant="outlined"
            fullWidth
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleUpdateCampaign} color="primary" autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

const mapStateToProps = state => {
  const { analytics } = state;
  return {
    common_analytics: analytics.common_analytics,
    ad_analytics: analytics.ad_analytics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAdAnalytics: bindActionCreators(getAdAnalytics, dispatch),
    getCommonAnalytics: bindActionCreators(getCommonAnalytics, dispatch),
    updateCampaignPrice: bindActionCreators(updateCampaignPrice, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);
