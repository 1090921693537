import React, { useState } from 'react';
import clsx from 'clsx';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardHeader,
  CardContent,
  Button,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Tooltip,
  TableSortLabel,
  TextField
} from '@material-ui/core';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';

import mockData from './data';
import { StatusBullet } from 'components';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 800
  },
  statusContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  status: {
    marginRight: theme.spacing(1)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const statusColors = {
  delivered: 'success',
  pending: 'info',
  refunded: 'danger'
};

const LatestOrders = props => {
  const { className, data, openModal, ...rest } = props;

  const classes = useStyles();

  const [orders] = useState(mockData);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      {/* <CardHeader
        // action={
        //   <Button
        //     color="primary"
        //     size="small"
        //     variant="outlined"
        //   >
        //     New entry
        //   </Button>
        // }
        title="Latest Orders"
      /> */}
      {/* <Divider /> */}
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Utm source</TableCell>
                  <TableCell>Offer</TableCell>
                  <TableCell>Medium</TableCell>
                  <TableCell>Campaign</TableCell>
                  <TableCell sortDirection="desc">
                    {/* <Tooltip enterDelay={300} title="Sort"> */}
                    {/* <TableSortLabel active direction="desc"> */}
                    Сумма вложения
                    {/* </TableSortLabel> */}
                    {/* </Tooltip> */}
                  </TableCell>
                  <TableCell>Кол-во пользователей</TableCell>
                  <TableCell>Кол-во оплативших</TableCell>
                  <TableCell>Прибыль</TableCell>
                  <TableCell>ROI</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map(item =>
                  item?.data?.map((el, e) => (
                    <TableRow hover key={e}>
                      <TableCell>{el?.utm || 'Не с рекламы'}</TableCell>
                      <TableCell>{el?.offer}</TableCell>
                      <TableCell>{el?.medium}</TableCell>
                      <TableCell>{el?.campaign}</TableCell>
                      <TableCell>
                        {el?.price || 0}
                        {/* <TextField value={el?.price} /> */}
                      </TableCell>
                      <TableCell>{el?.users_count}</TableCell>
                      <TableCell>
                        {el?.payments_count} | {el?.payments_this_month_count}
                      </TableCell>
                      <TableCell>
                        {el?.payments_amount - (el?.price || 0)} |{' '}
                        {el?.payments_this_month_amount - (el?.price || 0)}
                      </TableCell>
                      <TableCell>
                        <div className={classes.statusContainer}>
                          <StatusBullet
                            className={classes.status}
                            // color={statusColors[order.status]}
                            size="sm"
                          />
                          {el?.price
                            ? ((el?.payments_amount - (el?.price || 0)) * 100) /
                              (el?.price || 0)
                            : 0}
                          % |{' '}
                          {el?.price
                            ? ((el?.payments_this_month_amount -
                                (el?.price || 0)) *
                                100) /
                              (el?.price || 0)
                            : 0}
                          %
                        </div>
                      </TableCell>
                      <TableCell>
                        {!!el?.utm && (
                          <Button
                            color="primary"
                            size="small"
                            variant="text"
                            onClick={() => openModal(el)}>
                            Edit
                          </Button>
                        )}
                      </TableCell>
                    </TableRow>
                  ))
                )}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <Divider />
      {/* <CardActions className={classes.actions}>
        <Button
          color="primary"
          size="small"
          variant="text"
        >
          View all <ArrowRightIcon />
        </Button>
      </CardActions> */}
    </Card>
  );
};

LatestOrders.propTypes = {
  className: PropTypes.string
};

export default LatestOrders;
