import {
  SET_ANALYTICS
} from '../constants/paymentsAnalytics';
const initialState = {
  AllTimeAnalytics: [],
  MonthAnalytics: {}
};
export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return {
        ...state,
        AllTimeAnalytics: action.payload.AllTimeAnalytics,
        MonthAnalytics: action.payload.MonthAnalytics
      };
    default:
      return state;
  }
};
