import axios from 'axios';
import { baseUrl } from './baseUrl';

import {
  SET_USERS,
  SET_USER_HISTORY,
  UPDATE_USER_VALUE,
  UPDATE_USER_STATUSES,
  DONE
} from '../constants/user';

// const getToken = () => {
//   if (window.localStorage.getItem('token'))
//     return 'Bearer ' + window.localStorage.getItem('token');
//   else return false;
// };

// axios.defaults.headers.common['Authorization'] = getToken();
// const options = {
//   headers: {
//     'content-type': 'application/x-www-form-urlencoded'
//   }
// };

const getUsers = (searchParam, skip, limit = 20) => dispatch => {
  axios
    .get(
      `${baseUrl}/api/user/all?search=${searchParam}&skip=${skip}&limit=${limit}`
    )
    .then(res => {
      dispatch({
        type: SET_USERS,
        payload: {
          users: res.data?.users || [],
          count: res?.data?.count || 0
        }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const getUserHistory = (chat_id, skip) => dispatch => {
  axios
    .get(`${baseUrl}/api/user/history/${chat_id}/${skip}`)
    .then(res => {
      dispatch({
        type: SET_USER_HISTORY,
        payload: res?.data
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const getUserCallStatuses = (searchParam, skip) => dispatch => {
  axios
    .get(`${baseUrl}/api/user/users/status?search=${searchParam}&skip=${skip}`)
    .then(res => {
      dispatch({
        type: UPDATE_USER_VALUE,
        payload: {
          key: 'user_statuses',
          value: res.data.users
        }
      });
      dispatch({
        type: UPDATE_USER_VALUE,
        payload: {
          key: 'user_statuses_count',
          value: res.data.count
        }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const updateUserValue = (key, value) => dispatch => {
  dispatch({
    type: UPDATE_USER_VALUE,
    payload: {
      key,
      value
    }
  });
};

const updateUserCall = (id, data) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}/api/admin/user/status/${id}`, data)
      .then(res => {
        let user = res.data;
        delete user._id;
        dispatch({
          type: UPDATE_USER_STATUSES + DONE,
          payload: user
        });
        resolve(res.data);
      })
      .catch(err => {
        reject(err);
        console.log(err?.response);
      });
  });
};

export {
  getUsers,
  getUserHistory,
  updateUserValue,
  getUserCallStatuses,
  updateUserCall
};
