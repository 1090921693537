import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  IconButton,
  Menu,
  MenuItem
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';

import { getInitials } from 'helpers';
import { getUsers } from 'store/actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const { className, users, getUsers, count, openFilterModal, ...rest } = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    console.log(page, 90);
    setPage(page);
    getUsers(page * rowsPerPage, rowsPerPage);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
    getUsers(page * rowsPerPage, rowsPerPage);
  };

  const openHistory = chat_id => {
    window.location.replace(`/users/${chat_id}/timeline`);
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers?.length === users?.length}
                      color="primary"
                      indeterminate={
                        selectedUsers?.length > 0 &&
                        selectedUsers?.length < users?.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell>
                  <TableCell>Name</TableCell>
                  <TableCell>Email</TableCell>
                  <TableCell>Lastname</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell>Tariff</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user?.id}
                    // onClick={() => openHistory(user.chat_id)}
                    selected={selectedUsers?.indexOf(user?.id) !== -1}>
                    <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers?.indexOf(user?.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, user?.id)}
                        value="true"
                      />
                    </TableCell>
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar className={classes.avatar} src={user.avatarUrl}>
                          {getInitials(user?.first_name)}
                        </Avatar>
                        <Typography variant="body1">
                          {user?.first_name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user?.chat_id}</TableCell>
                    <TableCell>{user?.username}</TableCell>
                    <TableCell>{user?.phone}</TableCell>
                    <TableCell>
                      {moment(user?.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{user?.tariff || 'Нет тарифа'}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="more"
                        aria-controls={user._id}
                        aria-haspopup="true"
                        onClick={event => {
                          setAnchorEl({
                            id: user._id,
                            target: event.currentTarget
                          });
                        }}>
                        <MoreHorizIcon />
                      </IconButton>
                      <Menu
                        // id={user._id}
                        anchorEl={anchorEl?.target}
                        keepMounted
                        open={anchorEl?.id === user._id}
                        onClose={() => setAnchorEl(null)}>
                        <MenuItem onClick={() => openHistory(user.chat_id)}>
                          User History
                        </MenuItem>
                        <MenuItem onClick={() => openFilterModal(user)}>
                          Show Filters
                        </MenuItem>
                      </Menu>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={count || 0}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[5, 10, 20]}
        />
      </CardActions>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
