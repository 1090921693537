import axios from 'axios';
import { baseUrl } from './baseUrl';

import { SET_ANALYTICS } from '../constants/analytics';

// const getToken = () => {
//   if (window.localStorage.getItem('token'))
//     return 'Bearer ' + window.localStorage.getItem('token');
//   else return false;
// };

// axios.defaults.headers.common['Authorization'] = getToken();
// const options = {
//   headers: {
//     'content-type': 'application/x-www-form-urlencoded'
//   }
// };

const getConversions = () => dispatch => {
  axios
    .get(`${baseUrl}/api/admin/conversions`)
    .then(res => {
      dispatch({
        type: SET_ANALYTICS,
        payload: { key: 'conversions', value: res.data?.conversions }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const getAdAnalytics = (month, year) => dispatch => {
  axios
    .get(`${baseUrl}/api/admin/ad/analytics/${month}/${year}`)
    .then(res => {
      dispatch({
        type: SET_ANALYTICS,
        payload: { key: 'ad_analytics', value: res.data }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const getCommonAnalytics = () => dispatch => {
  axios
    .get(`${baseUrl}/api/admin/analytics`)
    .then(res => {
      dispatch({
        type: SET_ANALYTICS,
        payload: { key: 'common_analytics', value: res.data }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};

const updateCampaignPrice = (id, price) => dispatch => {
  return new Promise((resolve, reject) => {
    axios
      .put(`${baseUrl}/api/admin/campaign/${id}/price`, { price })
      .then(res => resolve(res))
      .catch(err => reject(err));
  });
};

const updateAnalytics = (key, value) => dispatch => {
  dispatch({
    type: SET_ANALYTICS,
    payload: {
      key,
      value
    }
  });
};

export {
  getConversions,
  updateAnalytics,
  getAdAnalytics,
  getCommonAnalytics,
  updateCampaignPrice
};
