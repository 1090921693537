import { SET_ANALYTICS } from '../constants/analytics';

const initialState = {
  conversions: [],
  ad_analytics: [],
  common_analytics: null
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_ANALYTICS:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    default:
      return state;
  }
};
