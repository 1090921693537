import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import {
  Grid
} from '@material-ui/core';
import { getAnalytics } from '../../store/actions/paymentsAnalyticsActions';
import { AnalyticsTable, MonthAnalyticsContainers } from './components';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const AnalyticsList = props => {
  const classes = useStyles();
  const { getAnalytics, AllTimeAnalytics, MonthAnalytics } = props;

  useEffect(() => {
    getAnalytics();
  });

  return (
    <div className={classes.root}>
      <div className={classes.content}>
        <MonthAnalyticsContainers MonthAnalytics={MonthAnalytics}/>
      </div>
      <div className={classes.content}>
        <AnalyticsTable
          analyticsByMonth={AllTimeAnalytics}
        />
      </div>
      
    </div>
  );
};

const mapStateToProps = state => {
  const { paymentsAnalytics } = state;
  return {
    AllTimeAnalytics: paymentsAnalytics.AllTimeAnalytics,
    MonthAnalytics: paymentsAnalytics.MonthAnalytics
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getAnalytics: bindActionCreators(getAnalytics, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AnalyticsList);
