import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardContent,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050

  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  },
  tableHead: {
    fontSize:16
  }
}));
function DivideHundredsWithSpace(number) {
  return number.replace(/(\d)(?=(\d\d\d)+([^\d]|$))/g, '$1 ')
}

const AnalyticsTable = props => {
  const { className, analyticsByMonth, ...rest } = props;

  const classes = useStyles();

  console.log(analyticsByMonth);
  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Период</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Всего пользователей</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Пользователей за месяц</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Всего оплат</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Кол-во оплат за месяц</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Новых оплат</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Повторных оплат</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Возвращенных оплат</Typography></TableCell>
                  <TableCell><Typography color="primary" className={classes.tableHead}>Смен тарифа</Typography></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {analyticsByMonth?.map(period => (
                  <TableRow
                    className={classes.tableRow}
                  // onClick={() => openHistory(user.chat_id)}
                  >
                    <TableCell><Typography variant="h5" color="textPrimary">{`${period?.Month}.${period?.Year}`}</Typography></TableCell>
                    <TableCell><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.TotalUsersCount.toString())}</Typography></TableCell>
                    <TableCell><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.UsersThisMonth.toString())}</Typography></TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.TotalPaymentsCount.toString())}</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.TotalIncome.toString())} тенге.</Typography></div>
                    </TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.ThisMonthPaymentsCount.toString())}</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.ThisMonthIncome.toString())} тенге.</Typography></div>
                    </TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.NewPaymentsCount.toString())} ({period?.NewPaymentsPercent}%)</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.NewPaymentsSum.toString())} тенге.</Typography></div>
                    </TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.RepeatedPaymentsCount.toString())} ({period?.RepeatedPaymentsPercent}%)</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.RepeatedPaymentsSum.toString())} тенге.</Typography></div>
                    </TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.ReturnedPaymentsCount.toString())} ({period?.ReturnedPaymentsPercent}%)</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.ReturnedPaymentsSum.toString())} тенге.</Typography></div>
                    </TableCell>
                    <TableCell>
                      <div><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.TariffChangesThisMonthCount.toString())} ({period?.TariffChangesThisMonthPercent}%)</Typography></div>
                      <div><Typography variant="h5" color="secondary">Доход:</Typography><Typography variant="h5" color="textPrimary">{DivideHundredsWithSpace(period?.TariffChangesThisMonthSum.toString())} тенге.</Typography></div>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
    </Card>
  );
};

AnalyticsTable.propTypes = {
  className: PropTypes.string,
  analyticsByMonth: PropTypes.array.isRequired
};

export default AnalyticsTable;
