import axios from 'axios';
import { baseUrl } from './baseUrl';

import {
	SET_ANALYTICS
} from "../constants/analytics";

const getAnalytics = () => dispatch => {
  axios.get(`${baseUrl}/fullanalytics`)
    .then(res => {
      dispatch({
        type: SET_ANALYTICS,
        payload: {
          AllTimeAnalytics: res.data?.AllTimeAnalytics || [],
          MonthAnalytics: res.data?.MonthAnalytics || {}
        }
      });
    })
    .catch(err => {
      console.log(err.response);
    });
};
export {
  getAnalytics
};
