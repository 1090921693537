import { combineReducers } from 'redux';

import paymentsReducer from './paymentsAnalytics'
import userReducer from './user';
import uiReducer from './ui';
import analyticsReducer from './analytics';

const rootReducer = combineReducers({
  user: userReducer,
  analytics: analyticsReducer,
  ui: uiReducer,
  paymentsAnalytics: paymentsReducer
});

export default rootReducer;
