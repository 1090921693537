import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';
import { Card, CardContent, Grid, Typography } from '@material-ui/core';
import Axios from 'axios';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    fontSize: 20
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const BonusInfo = props => {
  const { className, ...rest } = props;

  const classes = useStyles();

  const [statistics, setStatistics] = useState({});

  useEffect(() => {
    Axios.get('https://bot.fastbot.pro/bonus/list/statistics').then(data => {
      setStatistics(data.data);
    });
  }, []);

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent>
        <Grid container justify="space-between">
          <Grid item>
            <Typography className={classes.title} color="primary" gutterBottom>
              Бонусы
            </Typography>
            <Typography variant="h5" color="secondary">
              Отправлено:{' '}
              <Typography color="textPrimary" variant="h3">
                {statistics?.total}
              </Typography>
            </Typography>
            <Typography variant="h5" color="secondary">
              Активировали:{' '}
              <Typography color="textPrimary" variant="h3">
                {statistics?.activated}
              </Typography>
            </Typography>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
};

export default BonusInfo;
