import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { UsersToolbar } from 'views/UserList/components';
import UsersTable from './UsersTable';
import {
  getUserCallStatuses,
  updateUserCall
} from '../../store/actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = props => {
  const classes = useStyles();
  const { getUserCallStatuses, updateUserCall, users, count } = props;
  const [searchParam, setSearch] = useState('');
  const [skip, setSkip] = useState(0);

  const [isOpen, setModal] = useState(false);
  const [editedUser, setUser] = useState(null);
  const [user_id, setUserId] = useState(null);

  useEffect(() => {
    getUserCallStatuses(searchParam, skip);
  }, [searchParam, skip]);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const handleUpdate = (id, data) => {
    updateUserCall(id, data).then(res => {
      setModal(false);
    });
  };

  return (
    <div className={classes.root}>
      <UsersToolbar
        value={searchParam}
        onChange={value => handleSearch(value)}
      />
      <div className={classes.content}>
        <UsersTable
          users={users}
          handleUpdate={(id, data) => handleUpdate(id, data)}
          changePage={setSkip}
          count={count}
          isOpen={isOpen}
          setModal={setModal}
          editedUser={editedUser}
          setUser={setUser}
          user_id={user_id}
          setUserId={setUserId}
        />
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    users: user.user_statuses,
    count: user.user_statuses_count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserCallStatuses: bindActionCreators(getUserCallStatuses, dispatch),
    updateUserCall: bindActionCreators(updateUserCall, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
