import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import {
  AwaitingInfo,
  BestDayInfo,
  BonusReferralsInfo,
  ExpiredInfo,
  BonusInfo
} from './InfoContainers';
import PropTypes from 'prop-types';

const { Grid } = require('@material-ui/core');
const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(4)
  }
}));

const MonthAnalyticsContainers = props => {
  const classes = useStyles();
  const { MonthAnalytics, ...rest } = props;
  return (
    <div className={classes.root}>
      <Grid container spacing={4}>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <AwaitingInfo
            AwaitingThisMonth={MonthAnalytics?.AwaitingThisMonth}
            AwaitingToday={MonthAnalytics?.AwaitingToday}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <BestDayInfo
            DayWithMostPaymentsExpectedDate={
              MonthAnalytics?.DayWithMostPaymentsExpectedDate
            }
            DayWithMostPaymentsExpectedPaymentsCount={
              MonthAnalytics?.DayWithMostPaymentsExpectedPaymentsCount
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <BonusReferralsInfo
            BonusTariffPaymentsThisMonth={
              MonthAnalytics?.BonusTariffPaymentsThisMonth
            }
            ReferralsPaymentsThisMonth={
              MonthAnalytics?.ReferralsPaymentsThisMonth
            }
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <ExpiredInfo
            ExpiredExpectedPayments={MonthAnalytics?.ExpiredExpectedPayments}
          />
        </Grid>
        <Grid item lg={3} sm={6} xl={3} xs={12}>
          <BonusInfo />
        </Grid>
      </Grid>
    </div>
  );
};
MonthAnalyticsContainers.propTypes = {
  className: PropTypes.string
};

export default MonthAnalyticsContainers;
