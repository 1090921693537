import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import moment from 'moment';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { makeStyles } from '@material-ui/styles';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import {
  Card,
  CardActions,
  CardContent,
  Avatar,
  Checkbox,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
  TablePagination,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogTitle,
  DialogContentText,
  Button,
  DialogContent,
  Grid
} from '@material-ui/core';

import { getInitials } from 'helpers';

const useStyles = makeStyles(theme => ({
  root: {},
  content: {
    padding: 0
  },
  inner: {
    minWidth: 1050
  },
  nameContainer: {
    display: 'flex',
    alignItems: 'center'
  },
  avatar: {
    marginRight: theme.spacing(2)
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const UsersTable = props => {
  const {
    className,
    users,
    handleUpdate,
    changePage,
    count,
    editedUser,
    isOpen,
    user_id,
    setModal,
    setUserId,
    setUser,
    ...rest
  } = props;

  const classes = useStyles();

  const [selectedUsers, setSelectedUsers] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [page, setPage] = useState(0);
  const [interns, setInterns] = useState([
    'Айдана',
    'Фархат',
    'Дима',
    'Насиба',
    'Рома',
    'Жан',
    'Рустам'
  ]);

  const handleSelectAll = event => {
    const { users } = props;

    let selectedUsers;

    if (event.target.checked) {
      selectedUsers = users.map(user => user.id);
    } else {
      selectedUsers = [];
    }

    setSelectedUsers(selectedUsers);
  };

  const handleSelectOne = (event, id) => {
    const selectedIndex = selectedUsers.indexOf(id);
    let newSelectedUsers = [];

    if (selectedIndex === -1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers, id);
    } else if (selectedIndex === 0) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(1));
    } else if (selectedIndex === selectedUsers.length - 1) {
      newSelectedUsers = newSelectedUsers.concat(selectedUsers.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelectedUsers = newSelectedUsers.concat(
        selectedUsers.slice(0, selectedIndex),
        selectedUsers.slice(selectedIndex + 1)
      );
    }

    setSelectedUsers(newSelectedUsers);
  };

  const handlePageChange = (event, page) => {
    setPage(page);
    changePage(page * 20);
  };

  const handleRowsPerPageChange = event => {
    setRowsPerPage(event.target.value);
  };

  const openHistory = chat_id => {
    window.location.replace(`/users/${chat_id}/timeline`);
  };

  const updateModal = user => {
    setUserId(user._id);
    setUser({
      message: user?.message || null,
      status: user?.status || null,
      intern: user?.intern || null
    });
    setModal(true);
  };
  const updateUser = e => {
    setUser({
      ...editedUser,
      [e.target.name]: e.target.value
    });
  };

  return (
    <Card {...rest} className={clsx(classes.root, className)}>
      <CardContent className={classes.content}>
        <PerfectScrollbar>
          <div className={classes.inner}>
            <Table>
              <TableHead>
                <TableRow>
                  {/* <TableCell padding="checkbox">
                    <Checkbox
                      checked={selectedUsers?.length === users?.length}
                      color="primary"
                      indeterminate={
                        selectedUsers?.length > 0 &&
                        selectedUsers?.length < users?.length
                      }
                      onChange={handleSelectAll}
                    />
                  </TableCell> */}
                  <TableCell>Name</TableCell>
                  <TableCell>Chat id</TableCell>
                  <TableCell>Lastname</TableCell>
                  <TableCell>Phone</TableCell>
                  <TableCell>Registration date</TableCell>
                  <TableCell>Status</TableCell>
                  <TableCell>Message</TableCell>
                  <TableCell>Intern</TableCell>
                  <TableCell></TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {users?.slice(0, rowsPerPage).map(user => (
                  <TableRow
                    className={classes.tableRow}
                    hover
                    key={user?.id}
                    selected={selectedUsers?.indexOf(user?.id) !== -1}>
                    {/* <TableCell padding="checkbox">
                      <Checkbox
                        checked={selectedUsers?.indexOf(user?.id) !== -1}
                        color="primary"
                        onChange={event => handleSelectOne(event, user?.id)}
                        value="true"
                      />
                    </TableCell> */}
                    <TableCell>
                      <div className={classes.nameContainer}>
                        <Avatar className={classes.avatar} src={user.avatarUrl}>
                          {getInitials(user?.first_name)}
                        </Avatar>
                        <Typography variant="body1">
                          {user?.first_name}
                        </Typography>
                      </div>
                    </TableCell>
                    <TableCell>{user?.chat_id}</TableCell>
                    <TableCell>{user?.username}</TableCell>
                    <TableCell>{user?.phone}</TableCell>
                    <TableCell>
                      {moment(user?.createdAt).format('DD/MM/YYYY')}
                    </TableCell>
                    <TableCell>{user?.status || 'Not set'}</TableCell>
                    <TableCell>{user?.message}</TableCell>
                    <TableCell>{user?.intern || 'Not set'}</TableCell>
                    <TableCell>
                      <IconButton
                        aria-label="delete"
                        onClick={() => updateModal(user)}>
                        <EditIcon />
                      </IconButton>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </div>
        </PerfectScrollbar>
      </CardContent>
      <CardActions className={classes.actions}>
        <TablePagination
          component="div"
          count={count}
          onChangePage={handlePageChange}
          onChangeRowsPerPage={handleRowsPerPageChange}
          page={page}
          rowsPerPage={rowsPerPage}
          rowsPerPageOptions={[20]}
        />
      </CardActions>
      <Dialog
        open={isOpen}
        maxWidth={'sm'}
        minWidth={'sm'}
        onClose={() => setModal(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description">
        <DialogTitle id="alert-dialog-title">
          {'Update user status'}
        </DialogTitle>
        <DialogContent>
          <Grid container direction="column" spacing={2}>
            <Grid item xs={12}>
              <Select
                value={editedUser?.status || ''}
                name="status"
                onChange={updateUser}
                fullWidth
                variant="outlined"
                displayEmpty>
                <MenuItem value={''} disabled>
                  Set status
                </MenuItem>
                <MenuItem value="Купит">Купит</MenuItem>
                <MenuItem value="Не купит">Не купит</MenuItem>
              </Select>
            </Grid>
            <Grid item xs={12}>
              <TextField
                value={editedUser?.message}
                name="message"
                onChange={updateUser}
                label="Message"
                variant="outlined"
                fullWidth
              />
            </Grid>
            <Grid item xs={12}>
              <Select
                value={editedUser?.intern || ''}
                name="intern"
                variant="outlined"
                onChange={updateUser}
                fullWidth
                displayEmpty>
                <MenuItem value={''} disabled>
                  Set intern
                </MenuItem>
                {interns?.map((intern, i) => (
                  <MenuItem value={intern} key={i}>
                    {intern}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setModal(false)} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleUpdate(user_id, editedUser)}
            color="primary"
            autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Card>
  );
};

UsersTable.propTypes = {
  className: PropTypes.string,
  users: PropTypes.array.isRequired
};

export default UsersTable;
