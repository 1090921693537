import {
  SET_USERS,
  SET_USER_HISTORY,
  UPDATE_USER_VALUE,
  UPDATE_USER_STATUSES,
  DONE
} from '../constants/user';

const initialState = {
  users: [],
  user_history: {
    user: null,
    history: []
  },
  user_statuses: []
};

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_USERS:
      return {
        ...state,
        users: action.payload.users,
        count: action.payload.count
      };

    case SET_USER_HISTORY:
      const history = [...state.user_history?.history, action.payload?.history];
      return {
        ...state,
        user_history: {
          user: action.payload?.user || null,
          history:
            action.payload?.user?.chat_id !== state.user_history?.user?.chat_id
              ? action.payload?.history
              : history
        }
      };

    case UPDATE_USER_VALUE:
      return {
        ...state,
        [action.payload.key]: action.payload.value
      };

    case UPDATE_USER_STATUSES + DONE:
      let users = state.user_statuses.reduce((acc, item) => {
        if (item._id === action.payload.user_id) {
          return [...acc, { ...item, ...action.payload }];
        } else return [...acc, item];
      }, []);
      return {
        ...state,
        user_statuses: users
      };

    default:
      return state;
  }
};
