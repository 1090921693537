import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';

import { UsersToolbar, UsersTable, FilterModal } from './components';
import { getUsers } from '../../store/actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserList = props => {
  const classes = useStyles();
  const { getUsers, users, count } = props;
  const [searchParam, setSearch] = useState('');
  const [skip, setSkip] = useState(0);
  const [user, setUser] = useState(null);
  const [filterModal, setFilterModal] = useState(false);

  useEffect(() => {
    getUsers(searchParam, skip);
  }, [searchParam]);

  const handleSearch = e => {
    setSearch(e.target.value);
  };

  const openFilterModal = user => {
    setUser(user);
    setFilterModal(true);
  };

  return (
    <div className={classes.root}>
      <UsersToolbar
        value={searchParam}
        onChange={value => handleSearch(value)}
      />
      <div className={classes.content}>
        <UsersTable
          users={users}
          count={count}
          getUsers={(skipValue, limit) =>
            getUsers(searchParam, skipValue, limit)
          }
          openFilterModal={user => openFilterModal(user)}
        />
      </div>
      <FilterModal
        isOpen={filterModal}
        user={user}
        handleClose={() => setFilterModal(false)}
      />
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    users: user.users,
    count: user.count
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUsers: bindActionCreators(getUsers, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserList);
