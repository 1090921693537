import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  getUserHistory,
  updateUserValue
} from '../../store/actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const Conversions = props => {
  const classes = useStyles();
  const { getUserHistory, user_history, match } = props;
  const [chat_id, setChat_id] = useState(match?.params?.chat_id || '0');

  useEffect(() => {
    getUserHistory(chat_id, 0);
  }, []);

  //   const [users] = useState(mockData);
  console.log(user_history, 31);
  return (
    <div className={classes.root}>
      <h1>История действий пользователя {user_history?.user?.first_name}</h1>
      <div className={classes.content}>
        {user_history?.history?.length > 0 ? (
          <VerticalTimeline>
            {user_history?.history?.map((item, i) => (
              <VerticalTimelineElement
                key={i}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: 'rgb(33, 150, 243)',
                  color: '#fff'
                }}
                contentArrowStyle={{
                  borderRight: '7px solid  rgb(33, 150, 243)'
                }}
                date="2011 - present"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
              >
                <h3 className="vertical-timeline-element-title">
                  {item?.label}
                </h3>
                <p>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</p>
              </VerticalTimelineElement>
            ))}
          </VerticalTimeline>
        ) : (
          <p>История действий пользователя пуста</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    user_history: user.user_history
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserHistory: bindActionCreators(getUserHistory, dispatch),
    updateUserValue: bindActionCreators(updateUserValue, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Conversions);
