import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/styles';
import {
    Card,
    CardContent,
    Grid,
    Typography,
    Avatar,
    LinearProgress
} from '@material-ui/core';
import InsertChartIcon from '@material-ui/icons/InsertChartOutlined';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%'
  },
  content: {
    alignItems: 'center',
    display: 'flex'
  },
  title: {
    fontWeight: 700,
    fontSize: 20
  },
  avatar: {
    backgroundColor: theme.palette.success.main,
    height: 56,
    width: 56
  },
  icon: {
    height: 32,
    width: 32
  },
  difference: {
    marginTop: theme.spacing(2),
    display: 'flex',
    alignItems: 'center'
  },
  differenceIcon: {
    color: theme.palette.success.dark
  },
  differenceValue: {
    color: theme.palette.success.dark,
    marginRight: theme.spacing(1)
  }
}));

const BestDayInfo = props => {
    const { className, ...rest } = props;

    const classes = useStyles();

    return (
        <Card {...rest} className={clsx(classes.root, className)}>
            <CardContent>
                <Grid container justify="space-between">
                    <Grid item>
                        <Typography
                            className={classes.title}
                            color="primary"
                            gutterBottom>
                            Лучший день
                         </Typography>
                        <Typography variant="h5" color="secondary">Дата: <Typography color="textPrimary" variant="h3">{props?.DayWithMostPaymentsExpectedDate}</Typography></Typography>
                        <Typography variant="h5" color="secondary">Количество оплат в этот день: <Typography color="textPrimary" variant="h3">{props?.DayWithMostPaymentsExpectedPaymentsCount}</Typography></Typography>
                    </Grid>
                </Grid>
                {/* <LinearProgress
          className={classes.progress}
          value={75.5}
          variant="determinate"
        /> */}
            </CardContent>
        </Card>
    );
};

BestDayInfo.propTypes = {
    className: PropTypes.string
};

export default BestDayInfo;
