import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/styles';

import { SearchInput } from 'components';

import './style.css';
import NotificationModal from '../NotificationModal';

const useStyles = makeStyles(theme => ({
  row: {
    height: '42px',
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing(1)
  },
  spacer: {
    flexGrow: 1
  },
  importButton: {
    marginRight: theme.spacing(1)
  },
  exportButton: {
    marginRight: theme.spacing(1)
  },
  searchInput: {
    marginRight: theme.spacing(1)
  }
}));

const UsersToolbar = props => {
  const { className, value, ...rest } = props;

  const classes = useStyles();
  const [isOpenNotificationModal, setIsOpenNotificationModal] = useState(false);

  const handleChange = e => {
    props.onChange(e.target.value);
  };

  return (
    <div {...rest} className={clsx(classes.root, className)}>
      <div className={classes.row}>
        <span className={classes.spacer} />
      </div>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between'
        }}>
        <div className={classes.row}>
          <SearchInput
            className={classes.searchInput}
            placeholder="Search user"
            value={props?.value}
            onChange={props.onChange}
          />
        </div>
        <div>
          <button
            className="btn"
            onClick={() => setIsOpenNotificationModal(true)}>
            Send notification
          </button>
        </div>
      </span>

      <NotificationModal
        isOpen={isOpenNotificationModal}
        handleClose={() => setIsOpenNotificationModal(false)}
      />
    </div>
  );
};

UsersToolbar.propTypes = {
  className: PropTypes.string
};

export default UsersToolbar;
