import React, { useState, useEffect } from 'react';
import { makeStyles } from '@material-ui/styles';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import moment from 'moment';
import {
  VerticalTimeline,
  VerticalTimelineElement
} from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';
import {
  getUserHistory,
  updateUserValue
} from '../../store/actions/userActions';

const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(3)
  },
  content: {
    marginTop: theme.spacing(2)
  }
}));

const UserTimeline = props => {
  const classes = useStyles();
  const { getUserHistory, user_history, match } = props;
  const [chat_id, setChat_id] = useState(match?.params?.chat_id || '0');

  useEffect(() => {
    setChat_id(match?.params?.chat_id);
    updateUserValue('user_history', null);
  }, [match?.params?.chat_id]);

  useEffect(() => {
    getUserHistory(chat_id, 0);
  }, []);

  //   const [users] = useState(mockData);
  console.log(user_history, 31);
  return (
    <div className={classes.root}>
      <h1>История действий пользователя {user_history?.user?.first_name}</h1>
      <div className={classes.content}>
        {user_history?.history?.length > 0 ? (
          <VerticalTimeline>
            {user_history?.history?.map((item, i) => (
              <VerticalTimelineElement
                key={i}
                className="vertical-timeline-element--work"
                contentStyle={{
                  background: 'rgb(33, 150, 243)',
                  color: '#fff'
                }}
                contentArrowStyle={{
                  borderRight: '7px solid  rgb(33, 150, 243)'
                }}
                date="2011 - present"
                iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
                // icon={<WorkIcon />}
              >
                <h3 className="vertical-timeline-element-title">
                  {item?.label}
                </h3>
                <p>{moment(item.createdAt).format('DD.MM.YYYY HH:mm')}</p>
              </VerticalTimelineElement>
            ))}

            {/* <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2010 - 2011"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            // icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Art Director</h3>
            <h4 className="vertical-timeline-element-subtitle">
              San Francisco, CA
            </h4>
            <p>
              Creative Direction, User Experience, Visual Design, SEO, Online
              Marketing
            </p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2008 - 2010"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
            // icon={<WorkIcon />}
          >
            <h3 className="vertical-timeline-element-title">Web Designer</h3>
            <h4 className="vertical-timeline-element-subtitle">
              Los Angeles, CA
            </h4>
            <p>User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--work"
            date="2006 - 2008"
            iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}>
            <h3 className="vertical-timeline-element-title">Web Designer</h3>
            <h4 className="vertical-timeline-element-subtitle">
              San Francisco, CA
            </h4>
            <p>User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="April 2013"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            // icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Content Marketing for Web, Mobile and Social Media
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Online Course
            </h4>
            <p>Strategy, Social Media</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="November 2012"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            // icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Agile Development Scrum Master
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Certification
            </h4>
            <p>Creative Direction, User Experience, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            className="vertical-timeline-element--education"
            date="2002 - 2006"
            iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
            // icon={<SchoolIcon />}
          >
            <h3 className="vertical-timeline-element-title">
              Bachelor of Science in Interactive Digital Media Visual Imaging
            </h3>
            <h4 className="vertical-timeline-element-subtitle">
              Bachelor Degree
            </h4>
            <p>Creative Direction, Visual Design</p>
          </VerticalTimelineElement>
          <VerticalTimelineElement
            iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
            // icon={<StarIcon />}
          /> */}
          </VerticalTimeline>
        ) : (
          <p>История действий пользователя пуста</p>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  const { user } = state;
  return {
    user_history: user.user_history
  };
};

const mapDispatchToProps = dispatch => {
  return {
    getUserHistory: bindActionCreators(getUserHistory, dispatch),
    updateUserValue: bindActionCreators(updateUserValue, dispatch)
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserTimeline);
