import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';

const FilterModal = props => {
  return (
    <div>
      <Dialog
        open={props?.isOpen}
        onClose={props?.handleClose}
        scroll={'paper'}
        maxWidth={'md'}
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">User filters</DialogTitle>
        <DialogContent dividers={true}>
          {!!props?.user?.filters?.length ? (
            props?.user?.filters?.map((filter, f) => (
              <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">{f + 1}. Город:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {filter?.city_name || 'Все города'}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">Марка:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {filter?.brand || 'Все марки'}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">Модель:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {filter?.model || 'Все модели'}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">Год:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {filter?.yearStart} - {filter?.yearEnd}
                  </Grid>
                </Grid>
                <Grid container>
                  <Grid item xs={4}>
                    <Typography variant="h6">Цена:</Typography>
                  </Grid>
                  <Grid item xs={8}>
                    {filter?.priceStart} - {filter?.priceEnd}
                  </Grid>
                </Grid>
                <Divider />
              </DialogContentText>
            ))
          ) : (
            <Typography variant="h6">У пользователя нет фильтров</Typography>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={props?.handleClose} color="primary">
            OK
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default FilterModal;
