import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogActions,
  Button,
  Select
} from '@material-ui/core';
import React, { useState } from 'react';
import { Editor } from 'react-draft-wysiwyg';
import { EditorState } from 'draft-js';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { convertToHTML } from 'draft-convert';
import DOMPurify from 'dompurify';
import axios from 'axios';
import { baseUrl } from 'store/actions/baseUrl';

const NotificationModal = props => {
  const isOpen = props.isOpen || false;
  const handleClose = props.handleClose;

  const [sendTo, setSendTo] = useState('all');
  const [message, setMessage] = useState('');
  const [errMessage, setErrMessage] = useState('');
  const [editorState, setEditorState] = useState(() =>
    EditorState.createEmpty()
  );

  const [convertedContent, setConvertedContent] = useState(null);

  const handleEditorChange = state => {
    setEditorState(state);
    convertContentToHTML();
  };

  const convertContentToHTML = () => {
    let currentContentAsHTML = convertToHTML(editorState.getCurrentContent());
    setConvertedContent(currentContentAsHTML);
  };

  const createMarkup = html => {
    return DOMPurify.sanitize(html);
  };

  const getSendToTxt = to => {
    if (to === 'paid') {
      return 'Оплатившим';
    } else if (to === 'notpaid') {
      return 'Неоплатившим';
    }

    return 'Всем';
  };

  const sendToTelegram = (isTest = false) => {
    setErrMessage('');
    setMessage('Sending...');

    axios
      .post(`${baseUrl}/notification${isTest ? '/test' : ''}`, {
        text: createMarkup(convertedContent),
        to: sendTo
      })
      .then(d => {
        if (d.status === 200) {
          setMessage(
            `${d.data.message}${
              isTest
                ? ''
                : `\n${getSendToTxt(sendTo).toLowerCase()} (${d.data.count})`
            }`
          );
          setErrMessage('');
        }
      })
      .catch(e => {
        setErrMessage(e.response.data.message);
        setMessage('');
      });
  };

  const closeModal = () => {
    setErrMessage('');
    setMessage('');

    handleClose();
  };

  return (
    <div>
      <Dialog
        open={isOpen}
        onClose={handleClose}
        scroll={'paper'}
        maxWidth={'md'}
        fullWidth
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description">
        <DialogTitle id="scroll-dialog-title">Send notification</DialogTitle>
        <DialogContent id="scroll-dialog-description" tabIndex={-1}>
          <Editor
            editorState={editorState}
            onEditorStateChange={handleEditorChange}
          />
          <Select
            value={sendTo}
            onChange={event => {
              setSendTo(event.target.value);
            }}>
            <option value="all">All</option>
            <option value="paid">Paid</option>
            <option value="notpaid">Not paid</option>
          </Select>
          <div>
            <div style={{ color: 'green' }}>{message}</div>
            <div style={{ color: 'red' }}>{errMessage}</div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={closeModal} color="primary">
            Close
          </Button>
          <Button onClick={() => sendToTelegram(true)} color="primary">
            Test
          </Button>
          <Button onClick={() => sendToTelegram()} color="primary">
            Send
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default NotificationModal;
