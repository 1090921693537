import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Bar } from 'react-chartjs-2';
import { makeStyles } from '@material-ui/styles';
import {
  Card,
  CardHeader,
  CardContent,
  CardActions,
  Divider,
  Button
} from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { DatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import { options } from './chart';

const useStyles = makeStyles(() => ({
  root: {},
  chartContainer: {
    height: 400,
    position: 'relative'
  },
  actions: {
    justifyContent: 'flex-end'
  }
}));

const LatestSales = props => {
  const { className, data, selectedDate, handleDateChange, ...rest } = props;

  const classes = useStyles();

  const chartData = data.reduce(
    (acc, item) => {
      if (item)
        return {
          labels: [...acc.labels, item?._id || 'Не с рекламы'],
          datasets: [
            {
              ...acc.datasets[0],
              data: [
                ...acc.datasets[0].data,
                item?.data?.reduce(
                  (value, el) => value + el.payments_this_month_amount,
                  0
                )
              ]
            },
            {
              ...acc.datasets[1],
              data: [
                ...acc.datasets[1].data,
                item?.data?.reduce((value, el) => value + el.payments_amount, 0)
              ]
            }
          ]
        };
    },
    {
      labels: [],
      datasets: [
        {
          label: 'Прибыль оплативших в этом месяце',
          backgroundColor: '#3f51b5',
          data: []
        },
        {
          label: 'Прибыль оплативших',
          backgroundColor: '#43a047',
          data: []
        }
      ]
    }
  );

  return (
    <MuiPickersUtilsProvider utils={MomentUtils}>
      <Card {...rest} className={clsx(classes.root, className)}>
        <CardHeader
          action={
            <DatePicker
              variant="inline"
              openTo="year"
              views={['year', 'month']}
              label="Year and Month"
              helperText="Start from year selection"
              value={selectedDate}
              onChange={handleDateChange}
            />
            // <Button size="small" variant="text">
            //   Last 7 days <ArrowDropDownIcon />
            // </Button>
          }
          title="Аналитика рекламных кампаний"
        />
        <Divider />
        <CardContent>
          <div className={classes.chartContainer}>
            <Bar data={chartData} options={options} />
          </div>
        </CardContent>
        <Divider />
        {/* <CardActions className={classes.actions}>
        <Button color="primary" size="small" variant="text">
          Overview <ArrowRightIcon />
        </Button>
      </CardActions> */}
      </Card>
    </MuiPickersUtilsProvider>
  );
};

LatestSales.propTypes = {
  className: PropTypes.string
};

export default LatestSales;
