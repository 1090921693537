import { SNACKBAR_OPEN, SNACKBAR_CLEAR } from "../constants/ui";

const initialState = {
  snackbarOpen: false,
  snackbarMessage: false
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case SNACKBAR_OPEN:
      return {
        ...state,
        snackbarOpen: true,
        snackbarMessage: action.message
      };
      break;
    case SNACKBAR_CLEAR:
      return {
        ...state,
        snackbarOpen: false,
        snackbarMessage: ""
      };
      break;

    default:
      return state;
  }
};

export default reducer;
